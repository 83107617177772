<template>
  <div id="PaymentModule" class="ReferencePaymentMain">
    <nav>
      <v-app-bar color="white" flat app>
        <v-img max-width="90px" src="../../public/faviconrzhd.png" pl="0"></v-img>
        <v-toolbar-title class="ml-3">
          <div class="Header">Дирекция железнодорожных вокзалов —</div>
          <div class="text-none mr-3 font-family-RussianRail">филиал ОАО «РЖД»</div>
        </v-toolbar-title>
      </v-app-bar>
    </nav>
    <br />
    <br />
    <br />
    <div class="text-none mr-3 Payment">Оплата заказа № {{ orderNumber }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    returnUrl() {
      return window.location.protocol + '//' + window.location.host + '/complete?token=' + this.$route.query.token;
    },
    orderNumber() {
      return this.$route.query.order;
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  },
  mounted() {
    // var url = data.url + '&output=embed';
    // window.location.replace(url);
    let attachScript = document.createElement('script');
    attachScript.onload = () => {
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: this.$route.query.token,
        error_callback: function (err) {
          console.log('Error inside widget: ', err);
        },
        return_url: this.returnUrl
      });
      checkout.render('PaymentModule');
    };
    attachScript.setAttribute('src', 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js');
    document.head.appendChild(attachScript);
  }
};
</script>
<style>
.ReferencePaymentMain {
  font-weight: 400;
  font-family: RussianRail;
}
.Payment {
  text-align: center;
}
</style>
